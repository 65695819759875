import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';


export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
}

export const ASSETS = {
  SHOPIFY: require('./img/shopify.png'),
  WOOCOMMERCE: require('./img/woocommerce.png'),
  LOGO: {
    uri: 'https://images.squarespace-cdn.com/content/v1/5bbb12df93a63254abac75c6/aaeea927-b40b-4782-8778-e66f3bd972fe/blackpersonify.png?format=1500w',
  },
  TAGS_GUIDELINE: require('./img/tags-guideline.png'),
}